<template>
<div>

    <b-card v-if="userType =='Admin' || userType =='Productor'  || userType =='Operador' " > 
      <b-row class="justify-content-center" style="margin-top:-10px">
        <b-col class="mt-1" md="3" lg="3" style="padding: 0 35px;">          
          <b-button variant="primary" @click="viewProjectWeights" block>
            <feather-icon icon="SlidersIcon" class="mr-50"/>
            <span class="align-middle">Ver Pesajes</span>
          </b-button> 
        </b-col>
        
        <b-col class="mt-1" md="3" lg="3" style="padding: 0 35px;">
          <b-button variant="primary" @click="viewProjectEvents" block>
            <feather-icon icon="CalendarIcon" class="mr-50"/>
            <span class="align-middle">Ver Eventos</span>
          </b-button>           
        </b-col>        
      </b-row>      
    </b-card>
    
    <b-row v-if="!this.informationAvailable" class="justify-content-center">
      <b-col md="8" lg="8">
        <b-card>
          <b-row class="justify-content-center mt-1" style="text-align:center">
            <b-col xl="6" md="6" lg="6"  >
                <b-alert variant="primary" show>
                    <div class="alert-body">
                        <span><strong>Aún no hay pesajes reportados para este proyecto</strong></span>
                    </div>
                </b-alert>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    
    <b-row v-if="this.informationAvailable">
        <b-col offset-lg="2" offset-md="2" md="8" lg="8">        
          <b-card>   
          <b-row class="mb-0">
                  <b-col>
                      <h3>Seguimiento Proyecto # {{projectInformation.projectCode}} </h3>                    
                  </b-col>
                </b-row>         
            <b-row >              
              <b-col md="6" lg="6">
                
                <app-timeline>
                    <app-timeline-item variant="success" icon="UserIcon" style="margin-left:10px; padding:4px 20px">
                      <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
                        <h6 style="opacity:0.6">Productor: {{projectInformation.firstName}} {{projectInformation.lastName}} </h6>
                      </div>
                    </app-timeline-item>
                    <app-timeline-item variant="secondary" icon="MapPinIcon" style="margin-left:10px; padding:4px 20px">
                    <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mt-0 mb-sm-0">
                      <h6 style="opacity:0.6">Ubicación: {{projectInformation.locationAddress}}, {{projectInformation.locationState}} </h6>                     
                    </div>
                    </app-timeline-item>                    
                </app-timeline>
              </b-col>

              <b-col md="6" lg="6">
                <app-timeline>
                  <app-timeline-item variant="success" icon="CalendarIcon" style="margin-left:10px;padding:4px 20px">
                    <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
                      <h6 style="opacity:0.6">Inicio Aproximado: {{(projectInformation.startDate).substring(0, 10)}}</h6>
                    </div>
                  </app-timeline-item>                  
                  <app-timeline-item variant="warning" icon="CalendarIcon" style="margin-left:10px;padding:4px 20px">
                    <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
                      <h6 style="opacity:0.6">Cierre Aproximado: {{(projectInformation.endDate).substring(0, 10)}} </h6>                      
                    </div>                    
                  </app-timeline-item>                  
                </app-timeline>
              </b-col>
            </b-row>
          </b-card>
        </b-col>                
    </b-row>

    <b-row v-if="this.informationAvailable" style="align-items:center">
      <b-col offset-lg="2" offset-md="2" md="8" lg="8"> 
        <b-card >  
          <b-card-title>
              <b-row >
                  <b-col xl="12" md="12" lg="12" class="mt-1">
                      <h3 >
                          Consideraciones Importantes
                      </h3>
                  </b-col>
              </b-row>                    
          </b-card-title>
          <b-row>
              <b-alert variant="danger" show>
                  <div class="alert-body">
                      <small><strong>
                        Por cuestiones logisticas no en todos los pesajes es posible pesar a todos los animales, ya que estos algunas veces escapan de los corrales o se encuentran en lugares de dificil acceso, y por esto puede haber diferente cantidad de animales de un pesaje a otro, y no necesariamente son muertes.
                      </strong></small>
                  </div>
              </b-alert>
          </b-row>
          <b-row>
              <b-alert variant="danger" show>
                  <div class="alert-body">
                      <small><strong>
                        Cuando los animales llegan a las fincas, comienza un periodo de adaptación que puede durar varios meses (1-4 meses) en donde los animales pueden no obtener las ganancias de peso esperadas.
                      </strong></small>
                  </div>
              </b-alert>
          </b-row>
          <b-row>
              <b-alert variant="warning" show>
                  <div class="alert-body">
                      <small><strong>
                        La ganancia de peso de los animales no es lineal, o sea que, dependiendo de eventos climáticos, carga de la finca, o de la misma ubicación geográfica de la finca, unos pesajes pueden ganar más o ganar menos e incluso perder peso.
                      </strong></small>
                  </div>
              </b-alert>
          </b-row>
          <b-row>
              <b-alert variant="info" show>
                  <div class="alert-body">
                      <small><strong>
                        Cuando un proyecto inicia se compran y se transportan los animales a las fincas, y esto produce una pérdida del peso llamada 'merma' por eso los animales pesan menos cuando se entregan en las fincas
                      </strong></small>
                  </div>
              </b-alert>
          </b-row>
          <b-row>
              <b-alert variant="info" show>
                  <div class="alert-body">
                      <small><strong>
                        Las fechas de inicio y fin son tentativas, pues los procesos de compra y venta de ganado no suceden de un día para otro, y a veces debemos extenderlos para obtener mejores precios y/o negociaciones
                      </strong></small>
                  </div>
              </b-alert>
          </b-row>
        </b-card>
      </b-col>
    </b-row>

    <b-row v-if="this.informationAvailable" style="align-items:center">
      <b-col offset-lg="2" offset-md="2" md="8" lg="8"> 
        <b-card >  
          <b-card-title>
              <b-row >
                  <b-col xl="12" md="12" lg="12" class="mt-1">
                      <h3 >
                          Pesajes del Proyecto #{{this.projectInformation.projectCode}}
                      </h3>
                  </b-col>
              </b-row>                    
          </b-card-title>

          <b-table                 
              ref="selectableTable"
              :fields="fields" 
              :items="projectWeightsDetails"                
              responsive
              selectable
              class="mb-0"
              >
              
              <template #cell(pesoManada)="data">                    
                  {{parseFloat(data.item.pesoManada).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}} Kg
              </template> 

              <template #cell(weightDate)="data">
                  {{((data.item.weightDate).substring(0,10))}}                    
              </template>  
          </b-table>            
            <b-row v-if="this.projectWeightsDetails.length <= 0" class="justify-content-center mt-3" style="text-align:center">
                <b-col style="justify-content:center" xl="4" md="6" lg="4"  >
                    <b-alert variant="primary" show>
                        <div class="alert-body">
                            <span><strong>No Hay Pesajes para mostrar</strong></span>
                        </div>
                    </b-alert>
                </b-col>
            </b-row>
        </b-card>
      </b-col>
    </b-row>
        
    <b-row class="justify-content-center">
      <b-col md="8" lg="8">
        <b-card>
          <b-card-title>
            <h3>
              <feather-icon style="padding-bottom:3px" size="26" icon="ListIcon" />
              Actualizaciones y Documentos           
            </h3> 
          </b-card-title>

          <b-card-body >
            
              <b-row v-if="events.length <= 0" class="justify-content-center" style="text-align:center">
                <b-col xl="6" md="6" lg="6" >
                    <b-alert variant="primary" show>
                        <div class="alert-body">
                            <span><strong>Aún no hay eventos reportados para este proyecto</strong></span>
                        </div>
                    </b-alert>
                </b-col>
              </b-row>            

            <app-timeline>
              <app-timeline-item v-for="item in events" :key="item.projectEventID">
                <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
                  <h6>{{item.eventType}}</h6>
                  <small class="text-muted">{{(item.eventDate).substring(0,10)}}</small>
                </div>
                <p class="mb-0"><pre style="white-space: pre-wrap;">{{ item.description }}</pre></p>                
                <p  v-if="item.link"><a :href= item.link target="_blank">{{item.link}}</a></p>

                <b-row class="mt-2">                  
                  <p v-if="item.eventVideoUrl1 || item.eventVideoUrl2 || item.eventVideoUrl3">
                    <b-media-aside ref="video" style="margin-top:-15px; margin-right:-10px; margin-left:10px">
                      <b-avatar 
                        button @click="openModal(item)" size="48" variant="white">
                        <b-img :src= downImg height="auto" width="50px"/>
                      </b-avatar>
                    </b-media-aside>
                  </p>

                  <p v-if="item.checkImage1">
                    <b-media-aside style="margin-top:-15px; margin-right:-10px; margin-left:10px">
                        <b-avatar 
                          button @click="openModal(item)" size="48" variant="white" class="pull-up">
                          <b-img :src= item.picture1Url height="auto" width="50px"/>
                        </b-avatar>
                    </b-media-aside>
                  </p>

                  <p v-if="item.checkImage2">
                    <b-media-aside style="margin-top:-15px; margin-right:-10px">
                      <b-avatar button @click="openModal(item)" size="48" variant="white" class="pull-up">
                          <b-img :src= item.picture2Url height="auto" width="50px"/>
                        </b-avatar>
                    </b-media-aside>
                  </p>

                  <p v-if="item.checkImage3">
                    <b-media-aside style="margin-top:-15px; margin-right:-10px">
                      <b-avatar button @click="openModal(item)" size="48" variant="white" class="pull-up">
                          <b-img :src= item.picture3Url height="auto" width="50px"/>
                        </b-avatar>
                    </b-media-aside>
                  </p>

                  <p v-if="item.checkImage4">
                    <b-media-aside style="margin-top:-15px; margin-right:-10px">
                      <b-avatar button @click="openModal(item)" size="48" variant="white" class="pull-up">
                          <b-img :src= item.picture4Url height="auto" width="50px" />
                        </b-avatar>
                    </b-media-aside>
                  </p>

                  <p v-if="item.checkImage5">
                    <b-media-aside style="margin-top:-15px; margin-right:-10px">
                      <b-avatar button @click="openModal(item)" size="48" variant="white" class="pull-up">
                          <b-img :src= item.picture5Url height="auto" width="50px" />
                        </b-avatar>
                    </b-media-aside>
                  </p>

                  <p v-if="item.file1">
                    <b-media-aside style="margin-top:-15px; margin-right:1px; margin-left:25px">
                        <b-avatar button @click="openDocument(item.file1)" size="48" variant="white" class="pull-up">
                          <b-img :src="require('@/assets/images/icons/doc.png')" height="auto" width="20"/>
                        </b-avatar>
                    </b-media-aside>
                  </p>

                  <p v-if="item.file2">
                    <b-media-aside style="margin-top:-15px; margin-right:1px ; margin-left:10px">
                        <b-avatar button @click="openDocument(item.file2)" size="48" variant="white" class="pull-up">
                          <b-img :src="require('@/assets/images/icons/doc.png')" height="auto" width="20"/>
                        </b-avatar>
                    </b-media-aside>
                  </p>

                  <p v-if="item.file3">
                    <b-media-aside style="margin-top:-15px; margin-right:1px ; margin-left:10px">
                        <b-avatar button @click="openDocument(item.file3)" size="48" variant="white" class="pull-up">
                          <b-img :src="require('@/assets/images/icons/doc.png')" height="auto" width="20"/>
                        </b-avatar>
                    </b-media-aside>
                  </p>
                </b-row>
              </app-timeline-item>
            </app-timeline>

          </b-card-body>
        </b-card>
      </b-col>
    </b-row>  

    <b-modal ref="imagesModal" id="create-new-event" centered hide-footer title="Galeria de Imagenes" >
      <b-col md="12" lg="12" >        
          <b-carousel id="carousel-interval" controls indicators :interval="0">
            
            <b-carousel-slide v-if="event.eventVideoUrl1">
              <template #img>
                <iframe :src="eventVideoUrl1" class="sosty-project-details-video" frameborder="0" style="max-height:700px" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>                
              </template>
            </b-carousel-slide>

            <b-carousel-slide v-if="event.eventVideoUrl2">
              <template #img>
                <iframe :src="eventVideoUrl2" class="sosty-project-details-video" frameborder="0" style="max-height:700px" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </template>
            </b-carousel-slide>

            <b-carousel-slide v-if="event.eventVideoUrl3">
              <template #img>
                <iframe :src="eventVideoUrl3" class="sosty-project-details-video" frameborder="0" style="max-height:700px" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </template>
            </b-carousel-slide>

            <b-carousel-slide v-if="this.checkImage1" :img-src="this.checkImage1"  fluid alt="Responsive image" style="max-height:100%;max-width:100%" />
            <b-carousel-slide v-if="this.checkImage2" :img-src="this.checkImage2"  fluid alt="Responsive image" style="max-height:100%;max-width:100%" />
            <b-carousel-slide v-if="this.checkImage3" :img-src="this.checkImage3"  fluid alt="Responsive image" style="max-height:100%;max-width:100%" />
            <b-carousel-slide v-if="this.checkImage4" :img-src="this.checkImage4"  fluid alt="Responsive image" style="max-height:100%;max-width:100%" />
            <b-carousel-slide v-if="this.checkImage5" :img-src="this.checkImage5"  fluid alt="Responsive image" style="max-height:100%;max-width:100%" />
          </b-carousel>        
      </b-col>
    </b-modal>

</div>
</template>


<script>

import axios from '@axios'
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'
import {
  BCard, BCardText, BButton, BRow, BCol, BImg, BCardBody, BCardTitle, BCardSubTitle, BLink, BProgress, BProgressBar, BMedia, BAvatar, BMediaAside, BMediaBody, BEmbed, 
  BCardHeader, BCarousel, BCarouselSlide, BModal, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BAlert, BTooltip, BAvatarGroup,VBTooltip, BTable,
} from 'bootstrap-vue'
import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue'
import StatisticCardVertical from '@core/components/statistics-cards/StatisticCardVertical.vue'
import BCardCode from '@core/components/b-card-code'
import Cleave from 'vue-cleave-component'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import moment from 'moment';

const $trackBgColor = '#EBEBEB'
const $earningsStrokeColor2 = '#FF9F43'
const $earningsStrokeColor3 = '#00cfe8'

export default {
  components: {
    AppTimeline,
    AppTimelineItem,
    BCard,
    BCardText,    
    BAvatarGroup,
    BButton,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BCardHeader,
    BRow,
    BCol,
    BLink,
    BImg,
    BProgress,
    BProgressBar,
    BTable,
    StatisticCardHorizontal,
    StatisticCardVertical,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BCardCode,
    BEmbed,
    BCarousel, 
    BCarouselSlide,
    BModal,
    BFormGroup, 
    BFormInput, 
    BInputGroupAppend, 
    BInputGroup,
    Cleave,
    VueApexCharts,
    BAlert,
    BTooltip,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  directives: {
    'b-tooltip': VBTooltip,
  },

  data() {
    return {  
      projectInformation:{},
      investmentID: null, 
      events:[],
      checkImage1: null,
      checkImage2: null,
      checkImage3: null,
      checkImage4: null,
      checkImage5: null,
      file1: null,
      file2: null,
      file3: null,
      AmountOfAnimalsInTheLastWeight: null,
      Mortalidad: null,
      TazaDeMortalidad: null,
      valorGananciaActual: null,
      currentWeight: null,
      currentWeightPercentage: null,
      informationAvailable: false,
      projectID: null,
      userType: null,
      averageDailyFattening: null,
      initialWeight: null,        
      revenue: {},
      seriesDonut: [],
      status: '',
      emailConfirmationCode: null,
      project: {},
      fields: [
          { key: 'weightDate', label: 'Fecha pesaje' }, 
          { key: 'totalAnimals', label: 'Cantidad de Animales'},
          { key: 'pesoManada', label: 'Peso de Manada' },  
          { key: 'observations', label: 'Observaciones' },
      ],
      projectWeightsDetails:{},
      event:{},
      checkEventVideoUrl1: null,
      checkEventVideoUrl2: null,
      checkEventVideoUrl3: null,
      downImg: null,
      eventVideoUrl1: null,
      eventVideoUrl2: null,
      eventVideoUrl3: null,
      videosList: null,
    }
  },
  created() {
    if(this.$route != null && this.$route.query != null && this.$route.query.investmendID != null) {
      this.investmentID = this.$route.query.investmendID; 
      
      this.projectID = null
      var userData = getUserData();
      this.userType = userData.userType;      
      this.GetProjectProgressInformation();   
    } else if (this.$route != null && this.$route.query != null && this.$route.query.projectID != null) {
      var userData = getUserData();
      this.userType = userData.userType;
      this.projectID = this.$route.query.projectID;
      this.investmentID = null
      this.GetProjectProgressInformation();
    }
  },
  methods: {
    GetProjectProgressInformation(){
        axios.get('/Project/GetProjectProgressInformation', { params: { investmendID: this.investmentID, projectID: this.projectID }})        
        .then(response => {
          this.processing = false;
          this.projectInformation = response.data;
          this.GetProjectWeights();

          if(this.projectInformation.weights != 0){ 
            this.informationAvailable = true
            this.calculateAverageDailyFattening();

          } else {
            this.informationAvailable = false            
          }
          if(this.projectInformation.events != 0){
            this.events = this.projectInformation.events 
            this.calculateEvents();
          }
        })
    },    
    GetProjectWeights(){
      this.processing = true;
        axios.get('/Project/GetProjectWeights', { params: { projectID: this.projectInformation.projectID }})
        .then(response => {
            this.projectWeightsDetails = response.data;      
        })
    },
    calculateCurrentValue(){
      var inversionInicial = this.projectInformation.amountInvested;      
      var rentabilidadAnual = this.projectInformation.projectProfitability;      
      var rentabilidadMensual = rentabilidadAnual / 12      
      var duracionProyecto = this.projectInformation.projectDuration
      var rentabilidadTotalProyecto = rentabilidadMensual * duracionProyecto
      
      this.calculateBarWeightProgressBar()

      var valorPorcentajeGananciaActual = this.currentWeightPercentage * rentabilidadTotalProyecto/100
      this.valorGananciaActual = this.projectInformation.amountInvested * valorPorcentajeGananciaActual/100 + this.projectInformation.amountInvested      
    },
    calculateBarWeightProgressBar(){
      var lastWightIndex = this.projectInformation.weights.length - 1;
      this.currentWeight = this.data1[lastWightIndex].pesoManada        
      this.currentWeightPercentage = this.currentWeight * 100 / this.projectInformation.totalProjectWeigthGain
    },
    openDocument(fileUrl) {
      window.open(fileUrl, '_blank');
    },
    calculateEvents(){
      this.downImg = require('@/assets/images/pages/sostyVideo.jpg')
      this.events = this.projectInformation.events      
      this.events.forEach(item => {                      
        item.checkImage1 = item.picture1Url            
        item.checkImage2 = item.picture2Url
        item.checkImage3 = item.picture3Url
        item.checkImage4 = item.picture4Url
        item.checkImage5 = item.picture5Url
        item.file1 = item.file1Url
        item.file2 = item.file2Url
        item.file3 = item.file3Url  
        item.checkEventVideoUrl1 = item.eventVideoUrl1
        item.checkEventVideoUrl2 = item.eventVideoUrl2
        item.checkEventVideoUrl3 = item.eventVideoUrl3      
      }) 

    },
    viewProjectEvents(){
      let route = this.$router.resolve({path: 'project-events?projectID=' + this.projectInformation.projectID});
      window.open(route.href, '_blank');
    },
    viewProjectWeights(){
      let route = this.$router.resolve({path: 'project-weights?projectID=' + this.projectInformation.projectID});
      window.open(route.href, '_blank');
    },
    calculateAverageDailyFattening(){
      var inicioProyecto = new Date(this.projectInformation.startDate)
      var lastWightIndex = this.projectInformation.weights.length - 1;
      var fechaPesaje = new Date(this.projectInformation.weights[lastWightIndex].weightDate)     
      var diasDeEjecución = (Math.abs(fechaPesaje - inicioProyecto))/(1000*60*60*24)
      var pesoInicialAnimal = this.projectInformation.initialWeight 
      var cantidadAnimalesIniciales = this.projectInformation.amountOfCattles  
      var cantidadAnimalesActuales = this.AmountOfAnimalsInTheLastWeight
      var initialWeight = this.projectInformation.weights[0].pesoManada;      
      var gananciaPesoManada = this.projectInformation.weights[lastWightIndex].pesoManada - initialWeight
      var pesoPorAnimal = gananciaPesoManada/cantidadAnimalesActuales      
            
      if(diasDeEjecución == 0){
        this.averageDailyFattening = 0
      } else {
        var engordeDiarioPromedio = pesoPorAnimal/diasDeEjecución
        this.averageDailyFattening = (engordeDiarioPromedio*1000).toFixed(2)
      }      
    },          
    openModal(item){  
      this.event = item
      this.eventVideoUrl1 = this.event.eventVideoUrl1;
      this.eventVideoUrl2 = this.event.eventVideoUrl2;
      this.eventVideoUrl3 = this.event.eventVideoUrl3;      

      this.checkImage1 = (this.event.picture1Url)
      this.checkImage2 = (this.event.picture2Url)
      this.checkImage3 = (this.event.picture3Url)
      this.checkImage4 = (this.event.picture4Url)
      this.checkImage5 = (this.event.picture5Url)

      this.$refs['imagesModal'].show()          
    }
  },
}
</script>

<style lang="scss">
.card-body{
  position: relative;
  .pie-text{
    width: 80px;
    position:absolute;
    margin: auto;
    left: 0;
    right: 0;
    top: 30%;
    bottom: 0;
  }
  }
</style>
